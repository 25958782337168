import { atom, selector } from "recoil";
import { currentUserAtom } from "src/global-state/CurrentUserAtom";

export const showAdminControlsAtom = atom<boolean>({
  key: "ShowAdminControls",
  default: selector({
    key: "ShowAdminControls/Default",
    get: async ({ get }) => {
      const user = get(currentUserAtom);

      const isAdmin = user.role.name === "Administrator";
      return isAdmin;
    },
  }),
});
