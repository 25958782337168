import {
  Box,
  Typography,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Link,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { LinkedIn } from "@mui/icons-material";

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        justify-content: center;
        text-decoration: none;
        margin: 0;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "bold",
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      "0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
}));

function Unauthenticated() {
  return (
    <>
      <Helmet>
        <title>Status - Unauthorized</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <TooltipWrapper title="Zalgiris Employess' Tickets System" arrow>
            <LogoWrapper>
              <Box>
                <img
                  height="150px"
                  width="210px"
                  src="/static/images/logo/zalgiris_green.svg"
                  alt="Zalgiris"
                />
              </Box>
            </LogoWrapper>
          </TooltipWrapper>
          <Box textAlign="center">
            <Container maxWidth="xs">
              <Typography variant="h2" sx={{ mt: 4, mb: 2 }}>
                You are not authorized to view this website
              </Typography>
              <Typography
                variant="h3"
                color="text.secondary"
                fontWeight="normal"
                sx={{ mb: 4 }}
              >
                Wrong credentials
              </Typography>
            </Container>
            <img
              alt="Unauthorized"
              height={300}
              src="/static/images/status/unauthorized.svg"
            />
          </Box>
          <Divider sx={{ my: 4 }} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography component="span" variant="subtitle1">
                Phone:{" "}
              </Typography>
              <Typography
                component="span"
                variant="subtitle1"
                color="text.primary"
              >
                +370 615 98636
              </Typography>
            </Box>
            <Box>
              <Tooltip arrow placement="top" title="Facebook">
                <IconButton
                  color="primary"
                  href="https://www.facebook.com/bczalgiris/"
                  target="_blank"
                >
                  <FacebookIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="top" title="LinkedIn">
                <IconButton
                  color="primary"
                  href="https://www.linkedin.com/company/bc-zalgiris-kaunas"
                  target="_blank"
                >
                  <LinkedIn />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="top" title="Instagram">
                <IconButton
                  color="primary"
                  href="https://www.instagram.com/bczalgiriskaunas/"
                  target="_blank"
                >
                  <InstagramIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Container>
      </MainContent>
    </>
  );
}

export default Unauthenticated;
