import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";
import SuspenseLoader from "src/components/SuspenseLoader";
import AdminAuthorizedContext from "./contexts/AdminAuthorizedContext";
import ApprovedContext from "./contexts/ApprovedContext";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication

const AuthenticatedContext = Loader(
  lazy(() => import("src/contexts/AuthorizedContext"))
);

// Dashboards

const Overview = Loader(lazy(() => import("src/content/overview")));

// Locations
const Locations = Loader(
  lazy(() => import("src/content/applications/Locations"))
);

const LocationCreate = Loader(
  lazy(() => import("src/content/applications/Locations/Create"))
);

const LocationEdit = Loader(
  lazy(() => import("src/content/applications/Locations/Edit"))
);

// Users

const Users = Loader(lazy(() => import("src/content/applications/Users")));

const UserCreate = Loader(
  lazy(() => import("src/content/applications/Users/Create"))
);

const UserEdit = Loader(
  lazy(() => import("src/content/applications/Users/Edit"))
);

// Events

const Events = Loader(lazy(() => import("src/content/applications/Events")));

const EventInfo = Loader(
  lazy(() => import("src/content/applications/Events/Info"))
);
const EventCreate = Loader(
  lazy(() => import("src/content/applications/Events/Create"))
);
const EventEdit = Loader(
  lazy(() => import("src/content/applications/Events/Edit"))
);

// Event Categories

const EventCategories = Loader(
  lazy(() => import("src/content/applications/EventCategories"))
);
const EventCategoryCreate = Loader(
  lazy(() => import("src/content/applications/EventCategories/Create"))
);
const EventCategoryEdit = Loader(
  lazy(() => import("src/content/applications/EventCategories/Edit"))
);

// Reservation information

const HowReservationWorks = Loader(
  lazy(() => import("src/content/applications/HowReservationWorks"))
);

// Status

const Status404 = Loader(
  lazy(() => import("src/content/pages/Status/Status404"))
);

const routes: RouteObject[] = [
  {
    path: "",
    element: <AuthenticatedContext />,
    children: [
      {
        path: "",
        element: <ApprovedContext />,
        children: [
          {
            path: "",
            element: <BaseLayout />,
            children: [
              {
                path: "/",
                element: <Navigate to="/overview" replace />,
              },
              {
                path: "*",
                element: <Status404 />,
              },
            ],
          },
          {
            path: "overview",
            element: <SidebarLayout />,
            children: [
              {
                path: "",
                element: <Overview />,
              },
            ],
          },
          {
            path: "information",
            element: <SidebarLayout />,
            children: [
              {
                path: "",
                element: <HowReservationWorks />,
              },
            ],
          },
          {
            path: "events",
            element: <SidebarLayout />,
            children: [
              {
                path: "",
                element: <Events />,
              },
              {
                path: "info/:id",
                element: <EventInfo />,
              },
              {
                path: "",
                element: <AdminAuthorizedContext />,
                children: [
                  {
                    path: "create",
                    element: <EventCreate />,
                  },
                  {
                    path: "edit/:id",
                    element: <EventEdit />,
                  },
                ],
              },
            ],
          },
          {
            path: "eventCategories",
            element: <SidebarLayout />,
            children: [
              {
                path: "",
                element: <EventCategories />,
              },
              {
                path: "",
                element: <AdminAuthorizedContext />,
                children: [
                  {
                    path: "create",
                    element: <EventCategoryCreate />,
                  },
                  {
                    path: "edit/:id",
                    element: <EventCategoryEdit />,
                  },
                ],
              },
            ],
          },
          {
            path: "locations",
            element: <SidebarLayout />,
            children: [
              {
                path: "",
                element: <AdminAuthorizedContext />,
                children: [
                  {
                    path: "",
                    element: <Locations />,
                  },
                  {
                    path: "create",
                    element: <LocationCreate />,
                  },
                  {
                    path: "edit/:id",
                    element: <LocationEdit />,
                  },
                ],
              },
            ],
          },
          {
            path: "management",
            element: <SidebarLayout />,
            children: [
              {
                path: "",
                element: <Navigate to="userforms" replace />,
              },
              {
                path: "users",
                children: [
                  {
                    path: "",
                    element: <AdminAuthorizedContext />,
                    children: [
                      {
                        path: "",
                        element: <Users />,
                      },
                      {
                        path: "create",
                        element: <UserCreate />,
                      },
                      {
                        path: "edit/:id",
                        element: <UserEdit />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
