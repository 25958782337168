import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import NotApproved from "src/content/pages/NotApproved";
import { currentUserAtom } from "src/global-state/CurrentUserAtom";

const ApprovedContext: FC = (): JSX.Element => {
  const currentUser = useRecoilValue(currentUserAtom);
  return currentUser.isApproved ? <Outlet /> : <NotApproved />;
};

export default ApprovedContext;
