import { Box, FormControlLabel, List, ListItem, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { currentUserAtom } from "src/global-state/CurrentUserAtom";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { showAdminControlsAtom } from "src/global-state/ShowAdminControlsAtom";
import UserService from "src/services/UserService";
import { PutUser } from "src/contracts/user/PutUser";

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(["color", "fill"])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
  const [user, setUser] = useRecoilState(currentUserAtom);
  const refreshUser = useRecoilRefresher_UNSTABLE(currentUserAtom);

  const [showAdminControls, setShowAdminControls] = useRecoilState(
    showAdminControlsAtom
  );

  const handleNotifyChange = (): void => {
    setUser((prev) => ({
      ...prev,
      isNotificationsEnabled: !prev.isNotificationsEnabled,
    }));
    const putUser: PutUser = {
      name: user.name,
      surname: user.surname,
      email: user.email,
      isNotificationsEnabled: !user.isNotificationsEnabled,
    };

    UserService.put(putUser);
  };

  return (
    <>
      <ListWrapper
        sx={{
          display: {
            sm: "block",
          },
        }}
      >
        <List disablePadding component={Box} display="flex">
          {user.role.name === "Administrator" ? (
            <Box display={{ xs: "none", lg: "block" }}>
              <ListItem>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showAdminControls}
                      onChange={(event) =>
                        setShowAdminControls(event.target.checked)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Admin"
                />
              </ListItem>
            </Box>
          ) : (
            <></>
          )}
          <ListItem>
            <FormControlLabel
              control={
                <Switch
                  checked={user.isNotificationsEnabled}
                  onChange={(event) => handleNotifyChange()}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Notify"
            />
          </ListItem>
        </List>
      </ListWrapper>
    </>
  );
}

export default HeaderMenu;
