import { atom, selector } from "recoil";
import { UserResponse } from "src/contracts/user/UserResponse";
import UserService from "src/services/UserService";

export const currentUserAtom = atom<UserResponse>({
  key: "CurrentUser",
  default: selector({
    key: "CurrentUser/Default",
    get: async () => {
      const currentUser = await UserService.get();
      return currentUser;
    },
  }),
});
