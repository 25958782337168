import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import GradeTwoToneIcon from "@mui/icons-material/GradeTwoTone";
import { useRecoilValue } from "recoil";
import { currentUserAtom } from "src/global-state/CurrentUserAtom";

function HeaderButtons() {
  const user = useRecoilValue(currentUserAtom);
  return (
    <Box sx={{ mr: 1, display: "flex" }}>
      <Tooltip title="Rating" arrow>
        <Box>
          <IconButton color="primary" onClick={() => {}}>
            <Typography pt={0.1} pr={0.3}>
              {user.balance}
            </Typography>
            <GradeTwoToneIcon />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
}

export default HeaderButtons;
