import { PropsWithChildren } from "react";
import { useRecoilValue } from "recoil";
import { showAdminControlsAtom } from "src/global-state/ShowAdminControlsAtom";

interface AdminControlProps {}

const AdminControl = (props: PropsWithChildren<AdminControlProps>) => {
  const showAdminControls = useRecoilValue(showAdminControlsAtom);
  return showAdminControls ? <>{props.children}</> : <></>;
};

export default AdminControl;
