import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import { currentUserAtom } from "src/global-state/CurrentUserAtom";
import { useRecoilRefresher_UNSTABLE, useRecoilState } from "recoil";
import { showAdminControlsAtom } from "src/global-state/ShowAdminControlsAtom";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

function HeaderUserbox() {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserAtom);
  const refreshCurrentUser = useRecoilRefresher_UNSTABLE(currentUserAtom);
  const [showAdminControls, setShowAdminControls] = useRecoilState(
    showAdminControlsAtom
  );

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="rounded"
          {...stringAvatar(`${currentUser.name} ${currentUser.surname}`)}
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{currentUser.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {currentUser.role.name}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar
            variant="rounded"
            {...stringAvatar(`${currentUser.name} ${currentUser.surname}`)}
          />
          <UserBoxText>
            <UserBoxLabel variant="body1">{currentUser.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {currentUser.role.name}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/overview" component={NavLink}>
            <DashboardTwoToneIcon fontSize="small" />
            <ListItemText primary="Overview" />
          </ListItem>
          {currentUser.role.name === "Administrator" ? (
            <Box display={{ xs: "block", lg: "none" }} pl={1}>
              <ListItem>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showAdminControls}
                      onChange={(event) =>
                        setShowAdminControls(event.target.checked)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Admin"
                />
              </ListItem>
            </Box>
          ) : (
            <></>
          )}
        </List>
        {/* People did not understand the need for the sign out button */}
        {/* <Divider />
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              setCurrentUser(null);
              refreshCurrentUser();
            }}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box> */}
      </Popover>
    </>
  );
}

export default HeaderUserbox;
