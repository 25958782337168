import axiosCommon from "src/common/HttpCommon";
import { PutUser } from "src/contracts/user/PutUser";
import { UserResponse } from "src/contracts/user/UserResponse";

class UserService {
  readonly route = "/User";
  async get(): Promise<UserResponse> {
    try {
      const response = await axiosCommon.get<UserResponse>(`${this.route}`);
      return response.data;
    } catch (error: unknown) {
      return null;
    }
  }

  async login(): Promise<void> {
    await axiosCommon.get(`${this.route}/Login`);
  }

  async put(user: PutUser): Promise<void> {
    await axiosCommon.put(`${this.route}`, user);
  }
}

export default new UserService();
