import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Unauthenticated from "src/content/pages/Unauthenticated";
import { currentUserAtom } from "src/global-state/CurrentUserAtom";

const AdminAuthorizedContext: FC = (): JSX.Element => {
  const currentUser = useRecoilValue(currentUserAtom);

  return currentUser.role.name !== "Administrator" ? (
    <Unauthenticated />
  ) : (
    <Outlet />
  );
};

export default AdminAuthorizedContext;
